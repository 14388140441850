import * as React from "react";
import { PureComponent } from "react";
import { TemplateGraphqlResponse } from "./model";
import { GraphQLProps } from "../models/GraphQLProps";
import { graphql, PageProps } from "gatsby";
import { PillButtonRow } from "@components/Button/PillButtonRow";
import { DateTime } from "luxon";
import Row from "../components/Container/Row";
import Column from "../components/Container/Column";
import Layout from "../components/Layout";
import SEO from "../components/seo";
import "@themes/markdown-template.scss";
import "./templateStyle.css";

export type BlogTemplateProps = GraphQLProps<TemplateGraphqlResponse> &
    PageProps;

export default class BlogTemplate extends PureComponent<BlogTemplateProps> {
    constructor(props) {
        super(props);
    }

    render() {
        const { path, data } = this.props;
        const { markdownRemark } = data;
        const { frontmatter, html, htmlAst } = markdownRemark;
        const {
            title,
            description,
            date,
            keywords,
            demo,
            github,
            video,
            googlePlay,
            image,
            slug,
        } = frontmatter;
        const imageSrc = image?.childImageSharp.fixed.src;
        const keywordsArr = keywords.split(" ");
        const pillsProps = {
            demo,
            github,
            video,
            googlePlay,
        };
        const dateFormatted = DateTime.fromISO(date).toLocaleString(
            DateTime.DATE_FULL
        );
        return (
            <Layout>
                <SEO
                    title={title}
                    keywords={keywordsArr}
                    description={description}
                    pagePath={path}
                    imagePath={`${slug}/seo.png`}
                />
                <Row>
                    <Column>
                        <h1 className="blog-title">{title}</h1>
                        <time className="published" dateTime={date}>
                            {dateFormatted}
                        </time>
                        <PillButtonRow {...pillsProps} />
                        <div dangerouslySetInnerHTML={{ __html: html }} />
                    </Column>
                </Row>
            </Layout>
        );
    }
}

export const pageQuery = graphql`
    query($path: String!) {
        markdownRemark(frontmatter: { slug: { eq: $path } }) {
            html
            headings {
                depth
                value
            }
            frontmatter {
                date
                slug
                title
                description
                type
                keywords
                demo
                github
                video
                googlePlay
                image {
                    childImageSharp {
                        fixed(width: 400, height: 300) {
                            src
                        }
                    }
                }
            }
            htmlAst
        }
    }
`;
